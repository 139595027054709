import DefaultLayout from "../../layout/DefaultLayout"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import useStoryblok from "@mkt/utils/storyblok/useStoryblok"
import CardsType from "./CardsType"
import FeaturedCards from "@mkt/ui/components/common/FeaturedCards"
import Hero from "@mkt/ui/components/Ccbr/Hero"

import * as S from "./styles"

const Main = ({ pageContext, location }) => {
  const {productsHighlighted } = pageContext
  const data = useStoryblok(pageContext.content)
  const content = data.content || pageContext.content

  return (
    <DefaultLayout pageContext={pageContext} location={location} >
    <SbEditable content={content}>
      <section css={S.HomeCcbr}>
        <Hero content={content?.hero[0]} type="home"/>
        <CardsType blok={content?.lists_highlighted[0]} />
        <FeaturedCards type="home" blok={productsHighlighted} />
      </section>
    </SbEditable>
    </DefaultLayout>
  )
}

export default Main
