import { storyblokEditable } from "@storyblok/react"
import { Text, Button } from "iq-blueberry"
import Img from "@mkt/ui/components/common/Img"
import Padlock from "@mkt/ui/icons/Azulis/Padlock"
import * as S from "./styles.js"
import useCardsFeatured from "./hook.js"

const FeaturedCards = ({ type, blok, showSecureLink }) => {
  const { handleProductClick } = useCardsFeatured({ highlights: blok.body })
  return (
    <div
      {...storyblokEditable(blok)}
      css={S.cardsFeatured}
      className="cards-featured"
    >
      <div css={S.holder}>
        <Text
          as="h3"
          defaultColor="title"
          variant="heading-three"
          css={S.title}
        >
          {blok?.title}
        </Text>
        <div css={S.boxes} data-scroll="productListViewed">
          {blok?.body?.map((product, index) => {
            const parsedContent = JSON.parse(product?.node?.content)
            const data = parsedContent
            return (
              <div
                {...storyblokEditable(product)}
                css={S.box}
                key={product.id}
                data-position={index}
                data-scroll="productViewed"
              >
                <div className="cards-featured__figure">
                  <Img
                    alt={`Cartão ${data.title}`}
                    css={S.image}
                    height="108px"
                    src={data?.image?.filename}
                    width="170px"
                  />
                </div>
                <Text
                  as="h4"
                  defaultColor="title"
                  variant="heading-four"
                  css={S.subtitle}
                >
                  {data.title}
                </Text>
                <div css={S.benefitsWrapper}>
                  <div css={S.benefit}>
                    <Text as="span" defaultColor="common" variant="heading-six">
                      Renda Mínima:
                    </Text>
                    <Text as="span" defaultColor="common" variant="body-small">
                      {data?.minimum_income}
                    </Text>
                  </div>
                  <div css={S.benefit}>
                    <Text as="span" defaultColor="common" variant="heading-six">
                      Anuidade:
                    </Text>
                    <Text as="span" defaultColor="common" variant="body-small">
                      {data?.annuity}
                    </Text>
                  </div>
                </div>
                <div css={S.actions}>
                  <Button
                    onClick={() => handleProductClick(product, index)}
                    expand="x"
                    justify="center"
                  >
                    {type === "home" ? "Peça já" : "Saiba mais"}
                  </Button>
                  {showSecureLink && (
                    <div css={S.LinkSecureContent}>
                      <Padlock />
                      <Text as="p" variant={["TextSubTitle", "Text12"]}>
                        Link seguro
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FeaturedCards
