import { Link } from "gatsby"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { storyblokEditable } from "@storyblok/react"
import { Text } from "iq-blueberry"
import useCards from "./hooks.js"
import * as S from "./styles.js"
import Img from "@mkt/ui/components/common/Img/index.js"
import { render } from "storyblok-rich-text-react-renderer"

const CardsType = ({ blok }) => {
  const { mapIcon } = useCards()
  return (
    <SbEditable content={blok} css={S.CardsTypeSection}>
      <div css={S.CardsTypeContainer}>
        <div css={S.CardsTypeMainTitle}>
          <Text as="h2" css={S.CardsTypeMainTitle}>
            <span> {render(blok?.title)}</span>
          </Text>
        </div>
        <div css={S.CardsTypeInfos}>
          {blok?.boxes?.map((box) => {
            return (
              <Link
                {...storyblokEditable(box)}
                css={S.InfosWrapper}
                to={box?.button_link}
              >
                <div css={S.InfosBox}>
                  <div css={S.InfosBoxIconWrapper}>
                    <Img src={mapIcon[box.icon]} alt={box.alt}></Img>
                  </div>
                  <p className="infos-box__subtitle">{box?.subtitle}</p>
                  <h3 className="infos-box__title">{box?.title}</h3>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </SbEditable>
  )
}

export default CardsType
