import { css } from "@emotion/react"
import { colors } from "@mkt/ccbr/src/styles/Tokens"

export const HomeCcbr = css`
  [class$="SuggestedCardsBox"] {
    -webkit-transition: border 0.2s;

    &:hover {
      border: 2px solid ${colors.pink};
      cursor: pointer;
      transition: border 0.2s ease-in-out;
    }
  }
`
