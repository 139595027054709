import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

import { navigate } from "gatsby"
import { APP_NAME } from "gatsby-env-variables"

const useCardsFeatured = ({ highlights }) => {
  const { product, productsList, list } = useAnalytics()
  const currentListId = "cartoes-em-destaque"
  const currentListName = "Cartões em destaque"

  const handleProductClick = (card, position) => {
    const {
      content: { redirect_url: redirectUrl },
      full_slug,
    } = card

    const slug =
      APP_NAME === "ccbr"
        ? full_slug.replace("products/cartao-de-credito/", "")
        : full_slug.replace("products", "")

    const isProductRedirect = redirectUrl && redirectUrl.startsWith("https://www.iq.com.br/produto")
    const productSlug = isProductRedirect ? redirectUrl : slug

    product.setProduct({ list, position, ...card })

    navigate(productSlug)
  }

  useEffect(() => {
    list.setList({ data: { id: currentListId, name: currentListName } })
  }, [])

  useEffect(() => {
    if (!productsList.data) {
      productsList.setProductsList(highlights)
    }
  }, [productsList.data])

  return {
    handleProductClick,
  }
}

export default useCardsFeatured
