import { MoneyBadge24, Plane24, SupermarketKart24, ZeroAnnuity24, } from "@mkt/ui/icons/Ccbr/png"

const useCards = () => {

 const mapIcon = {
  MoneyBadge: MoneyBadge24,
  Plane: Plane24,
  SupermarketKart: SupermarketKart24,
  ZeroAnnuity: ZeroAnnuity24,
 }

 return {
  mapIcon,
 }

}

export default useCards